import React, { useState, useEffect } from "react";
import { Layout } from "./Layout";
import { doc, setDoc } from "firebase/firestore";
import { db, auth } from "../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export function Prioritäten() {
  const navigate = useNavigate();
  let uid;
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in
      uid = user.uid;
    } else {
      //not signed in
      return navigate("/login");
    }
  });
  // const [points, setPoints] = useState({
  //   Freizeit: 0,
  //   Einkommen: 0,
  //   Verantwortung: 0,
  //   Kundenkontakt: 0,
  //   Freiheit: 0,
  //   "Menschen helfen": 0,
  // });

  // const handlePointsChange = (category, value) => {
  //   const updatedPoints = {
  //     ...points,
  //     [category]: value,
  //   };

  //   const totalPoints = Object.values(updatedPoints).reduce(
  //     (acc, cur) => acc + cur,
  //     0
  //   );

  //   if (totalPoints <= 30) {
  //     setPoints(updatedPoints);
  //   }
  // };

  const [ranking, setRanking] = useState([]);

  const handleRankingChange = (newRanking) => {
    setRanking(newRanking);
    console.log(ranking);
  };

  const handleButtonClick = () => {
    // Divide all point values by 10
    // const transformedPoints = Object.fromEntries(
    //     Object.entries(points).map(([key, value]) => [key, value / 10])
    // );

    setDoc(
      doc(db, "users", uid),
      {
        [ranking[0]]: 1,
        [ranking[1]]: 0.9,
        [ranking[2]]: 0.8,
        [ranking[3]]: 0.7,
        [ranking[4]]: 0.6,
        [ranking[5]]: 0.5,
        [ranking[6]]: 0.4,
        [ranking[7]]: 0.3,
        [ranking[8]]: 0.2,
        [ranking[9]]: 0.1,
      },
      { merge: true }
    )
      .then(() => {
        console.log("User added to Firestore vorstellung");
        return navigate("../Overview");
      })
      .catch((error) => {
        console.log("Error adding user to Firestore:", error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrollen zur obersten Position der Seite
  }, []); // Wird nur beim ersten Rendern ausgeführt

  return (
    <Layout pageTitle="Prioritäten">
      <div className="max-w-4xl mx-auto flex flex-col justify-center items-center px-2 py-8 ">
        <div className="bg-white rounded-xl text-primary shadow-sm py-5 px-2 text-center max-w-lg">
          Sortiere die folgenden Bereiche nach der Priorität, die sie für dich
          persönlich haben. Mit <span className=" text-slate-500">X</span>{" "}
          kannst du deine Eingabe löschen. <br />
        </div>
        <RankingSelectComponent onRankingChange={handleRankingChange} />

        {/* <div className="flex flex-col bg-primary text-center rounded-xl my-5 py-10">
          <div className="text-white font-bold text-3xl p-5">
            Verfügbare Punkte:{" "}
            {30 - Object.values(points).reduce((acc, cur) => acc + cur, 0)}
          </div>
          <div>
            <CategoryInput
              category="Freizeit"
              value={points.Freizeit}
              onChange={(value) => handlePointsChange("Freizeit", value)}
            />
            <CategoryInput
              category="Einkommen"
              value={points.Einkommen}
              onChange={(value) => handlePointsChange("Einkommen", value)}
            />
            <CategoryInput
              category="Verantwortung"
              value={points.Verantwortung}
              onChange={(value) => handlePointsChange("Verantwortung", value)}
            />
            <CategoryInput
              category="Kundenkontakt"
              value={points.Kundenkontakt}
              onChange={(value) => handlePointsChange("Kundenkontakt", value)}
            />
            <CategoryInput
              category="Freiheit"
              value={points.Freiheit}
              onChange={(value) => handlePointsChange("Freiheit", value)}
            />
            <CategoryInput
              category="Good_Cause"
              value={points.Good_Cause}
              onChange={(value) => handlePointsChange("Good_Cause", value)}
            />
          </div>
        </div> */}

        <button
          onClick={handleButtonClick}
          disabled={ranking.length !== 10} // Button wird aktiviert, wenn das Ranking vollständig ist
          className={`btn btn-accent rounded-3xl shadow-lg font-bold px-16 mx-auto ${
            ranking.length !== 10 ? "brightness-75" : "" // Button wird ausgegraut, wenn das Ranking nicht vollständig ist
          }`}
        >
          Fertig
        </button>
      </div>
    </Layout>
  );
}

// const CategoryInput = ({ category, value, onChange }) => {
//   return (
//     <div className="md:grid md:grid-cols-2 md:gap-4 items-center md:ml-10 py-2">
//       <label className=" text-left min-w-max mr-2">{category}</label>
//       <div>
//         <button
//           className="w-20 md:w-10 p-2 border rounded-l bg-red-300 text-primary"
//           onClick={() => onChange(Math.max(0, value - 1))}
//         >
//           -
//         </button>
//         <input
//           className="w-15 md:w-10 border-t border-b text-center text-primary font-semibold py-2 rounded-none"
//           type="text" // Changed to text to avoid native arrows
//           value={value === 0 ? 0 : value}
//           onChange={(e) => {
//             let newValue = parseInt(e.target.value, 10);
//             newValue = isNaN(newValue) ? 0 : newValue;
//             newValue = Math.max(newValue, 0);
//             onChange(newValue);
//           }}
//         />
//         <button
//           className="w-20 md:w-10 p-2 border rounded-r bg-green-300 text-primary"
//           onClick={() => onChange(value + 1)}
//         >
//           +
//         </button>
//       </div>
//     </div>
//   );
// };

const RankingSelectComponent = ({ onRankingChange }) => {
  const [selectedOptions, setSelectedOptions] = useState(Array(5).fill(null));
  const [ranking, setRanking] = useState([]);

  const handleSelectChange = (index, event) => {
    const { value } = event.target;
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = value;
    setSelectedOptions(newSelectedOptions);

    // Wenn alle Optionen ausgewählt wurden, speichere die Rangliste
    if (!newSelectedOptions.includes(null)) {
      setRanking(newSelectedOptions);
      onRankingChange(newSelectedOptions); // Rangliste an Hauptkomponente übergeben
    }
  };

  const handleClearClick = (index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[index] = null;
    setSelectedOptions(newSelectedOptions);

    // Wenn alle Optionen ausgewählt wurden, setze Rangliste zurück
    if (ranking.length === selectedOptions.length) {
      setRanking([]);
      onRankingChange([]);
    }
  };

  return (
    <div className="flex flex-col items-center gap-8 bg-primary rounded-xl p-10 my-8 w-full max-w-lg font-semibold">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((position) => (
        <div key={position} className="flex items-center">
          <span className="mr-4 text-slate-500 font-bold">{position}.</span>
          <select
            className="select select-accent w-full bg-white text-primary"
            value={selectedOptions[position - 1] || ""}
            onChange={(event) => handleSelectChange(position - 1, event)}
          >
            <option disabled selected value="">
              Platz {position}
            </option>
            <option
              value="Guter_Zweck"
              disabled={selectedOptions.includes("Guter_Zweck")}
            >
              Guter Zweck
            </option>
            <option
              value="Karrieremöglichkeiten"
              disabled={selectedOptions.includes("Karrieremöglichkeiten")}
            >
              Karrieremöglichkeiten
            </option>
            <option
              value="Status"
              disabled={selectedOptions.includes("Status")}
            >
              Status
            </option>
            <option
              value="Arbeit_im_Freien"
              disabled={selectedOptions.includes("Arbeit_im_Freien")}
            >
              Arbeiten im Freien
            </option>
            <option
              value="Work_Life_Balance"
              disabled={selectedOptions.includes("Work_Life_Balance")}
            >
              Work-Life-Balance
            </option>
            <option
              value="Arbeit_mit_Menschen"
              disabled={selectedOptions.includes("Arbeit_mit_Menschen")}
            >
              Arbeiten mit Menschen
            </option>
            <option
              value="Jobsicherheit"
              disabled={selectedOptions.includes("Jobsicherheit")}
            >
              Jobsicherheit
            </option>
            <option
              value="intellektuelle_Herausforderung"
              disabled={selectedOptions.includes(
                "intellektuelle_Herausforderung"
              )}
            >
              Intellektuelle Herausforderung
            </option>
            <option
              value="Eigenverantwortung"
              disabled={selectedOptions.includes("Eigenverantwortung")}
            >
              Eigenverantwortung
            </option>
            <option
              value="Geografische_Mobilität"
              disabled={selectedOptions.includes("Geografische_Mobilität")}
            >
              Geografische Mobilität
            </option>
          </select>
          <button
            className="ml-2 focus:outline-none"
            onClick={() => handleClearClick(position - 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-slate-500 stroke-2"
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      ))}
    </div>
  );
};

// const RankingComponent = () => {
//   // State für Felder und Rangliste
//   const [fields, setFields] = useState([
//     { id: 1, title: "Kundenkontakt" },
//     { id: 2, title: "Freizeit" },
//     { id: 3, title: "Einkommen" },
//     { id: 4, title: "Verantwortung" },
//     { id: 5, title: "Menschen helfen" },
//   ]);
//   const [ranking, setRanking] = useState([]);

//   // Drag-and-Drop-Logik
//   const onDragEnd = (result) => {
//     if (!result.destination) return;
//     const items = Array.from(fields);
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);
//     setFields(items);
//     setRanking(items.map((field) => field.title));
//   };

//   return (
//     <DragDropContext onDragEnd={onDragEnd}>
//       <div className="flex flex-col my-10 items-center w-full">
//         {/* Anzeige der Felder */}
//         <Droppable droppableId="fields">
//           {(provided) => (
//             <div
//               {...provided.droppableProps}
//               ref={provided.innerRef}
//               className="space-y-5 p-10 rounded-xl font-semibold bg-primary text-primary text-lg"
//             >
//               {fields.map((field, index) => (
//                 <Draggable
//                   key={field.id}
//                   draggableId={field.id.toString()}
//                   index={index}
//                 >
//                   {(provided) => (
//                     <div
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                       className="field bg-white rounded-lg px-5 py-1"
//                     >
//                       {field.title}
//                     </div>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </div>
//           )}
//         </Droppable>
//         {/* Anzeige der Rangliste */}
//         {/* <div className="w-3/4 flex justify-between">
//           {ranking.map((field, index) => (
//             <div
//               key={index}
//               className="w-1/5 border border-gray-300 p-2 text-center"
//             >
//               {index + 1}. {field}
//             </div>
//           ))}
//         </div> */}
//       </div>
//     </DragDropContext>
//   );
// };
