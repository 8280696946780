import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import { data, pca_components, pca_means } from './data.js';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { Layout } from '../Layout.js';
import profileUserImage from '../../assets/profile-user.png';

// Function to project the user vector into PCA space
const projectToPCASpace = (vector) => {
  if (!vector || !pca_components || !pca_means) return null;

  // Center the new point by subtracting the mean
  const centeredPoint = vector.map(
    (value, index) => value - pca_means[index]
  );

  // Project the centered point using the PCA components
  return pca_components.map((component) =>
    component.reduce(
      (sum, value, index) => sum + value * centeredPoint[index],
      0
    )
  );
};

// Map 'Kategorie' to specific colors
const colorMap = {
  'Medizin & Gesundheitswesen': '#FF6F61',
  'Medien & Kommunikation': '#4A90E2',
  'Wirtschaft & Recht': '#2E4057',
  'Technik & Ingenieurwissenschaften': '#FFB400',
  Gesellschaftswissenschaften: '#7E57C2',
  Naturwissenschaften: '#2ECC71',
  'Sprach- & Kulturwissenschaften': '#FF8A65',
  'Kunst & Musik': '#D81B60',
  'Informatik & Mathematik': '#3498DB',
  'Agrar- & Forstwissenschaften': '#27AE60',
};

const getColorForKategorie = (kategorie) =>
  colorMap[kategorie] || '#d1d5db';

export const Map = () => {
  // Extract data for the scatter plot
  const xValues = data.map((item) => item.PCA1);
  const yValues = data.map((item) => item.PCA2);
  const textValues = data.map((item) => item.name);
  const colorValues = data.map((item) =>
    getColorForKategorie(item.Kategorie)
  );

  const [userPcaProjection, setUserPcaProjection] = useState(null);
  const [markerSize, setMarkerSize] = useState(8); // Initial marker size
  const [imageSizeX, setImageSizeX] = useState(0.04); // Initial image size
  const [imageSizeY, setImageSizeY] = useState(0.04);
  const plotRef = useRef(null);

  // State to store the current axis ranges
  const [axisRanges, setAxisRanges] = useState(null);

  const fieldNames = [
    'Verträglichkeit',
    'Gewissenhaftigkeit',
    'Extrovertiert',
    'Neurotizismus',
    'Offenheit',
    'Kreativität',
    'Empathie',
    'Zahlenreihen',
    'Sprache',
    'Koordinieren',
    'Helfen',
    'Kreieren',
    'Analysieren',
    'Verhandeln',
    'Naturwissenschaften',
    'Technik',
    'Gesellschaft',
    'Künste',
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const uid = user.uid;
          const docRef = doc(db, 'users', uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();

            // Extract the user vector with default values
            const userVector = fieldNames.map(
              (field) => userData[field] ?? 99
            );
            console.log(userVector);

            // Project the user vector into PCA space
            const projection = projectToPCASpace(userVector);
            setUserPcaProjection(projection);

            // Compute zoomed-in axis ranges centered on user point
            const xValuesRange = Math.max(...xValues) - Math.min(...xValues);
            const yValuesRange = Math.max(...yValues) - Math.min(...yValues);
            const zoomFactor = 0.5; // Adjust between 0 and 1 (smaller means more zoomed in)
            const xRange = [
              projection[0] - (xValuesRange * zoomFactor) / 2,
              projection[0] + (xValuesRange * zoomFactor) / 2,
            ];
            const yRange = [
              projection[1] - (yValuesRange * zoomFactor) / 2,
              projection[1] + (yValuesRange * zoomFactor) / 2,
            ];
            setAxisRanges({ xaxis: xRange, yaxis: yRange });
          } else {
            console.error('No such document!');
            setUserPcaProjection(null);
            // Set default axisRanges if user data is not available
            setAxisRanges({
              xaxis: [Math.min(...xValues), Math.max(...xValues)],
              yaxis: [Math.min(...yValues), Math.max(...yValues)],
            });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserPcaProjection(null);
          setAxisRanges({
            xaxis: [Math.min(...xValues), Math.max(...xValues)],
            yaxis: [Math.min(...yValues), Math.max(...yValues)],
          });
        }
      } else {
        console.log('User not logged in');
        setUserPcaProjection(null);
        setAxisRanges({
          xaxis: [Math.min(...xValues), Math.max(...xValues)],
          yaxis: [Math.min(...yValues), Math.max(...yValues)],
        });
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  // Compute initial x and y ranges
  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  // Store initial ranges
  const [initialXRange] = useState(xMax - xMin);
  const [initialYRange] = useState(yMax - yMin);

  const handleRelayout = (event) => {
    if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      setAxisRanges({
        xaxis: [event['xaxis.range[0]'], event['xaxis.range[1]']],
        yaxis: [event['yaxis.range[0]'], event['yaxis.range[1]']],
      });

      const currentXRange =
        event['xaxis.range[1]'] - event['xaxis.range[0]'];
      const currentYRange =
        event['yaxis.range[1]'] - event['yaxis.range[0]'];

      const zoomFactorX = initialXRange / currentXRange;
      const zoomFactorY = initialYRange / currentYRange;

      const zoomFactor = Math.max(zoomFactorX, zoomFactorY);

      const newMarkerSize = Math.min(8 * zoomFactor, 50);
      setMarkerSize(newMarkerSize);

      // Update image size based on zoom level
      const newImageSize = Math.min(0.2 / zoomFactor, 0.04); // Adjust as needed
      setImageSizeX(newImageSize);
      setImageSizeY(newImageSize);
    }
  };

  return (
    <Layout>
      <div className="w-full h-screen flex justify-center px-7 pt-7">
        <div className="w-full h-full rounded-lg border border-gray-300 overflow-hidden">
          {axisRanges && (
            <Plot
              ref={plotRef}
              data={[
                {
                  x: xValues,
                  y: yValues,
                  text: textValues,
                  type: 'scattergl',
                  mode: 'markers',
                  marker: { color: colorValues, size: markerSize },
                  hovertemplate: '%{text}<extra></extra>',
                  showlegend: false,
                },
                // Removed the user's marker trace
              ]}
              layout={{
                autosize: true,
                dragmode: 'zoompan',
                xaxis: {
                  visible: true,
                  showgrid: true,
                  gridcolor: 'lightgray',
                  gridwidth: 1,
                  griddash: 'dash',
                  zeroline: false,
                  range: axisRanges.xaxis,
                  autorange: false,
                  showticklabels: false,
                  ticks: '',
                  showline: false,
                },
                yaxis: {
                  visible: true,
                  showgrid: true,
                  gridcolor: 'lightgray',
                  gridwidth: 1,
                  griddash: 'dash',
                  zeroline: false,
                  range: axisRanges.yaxis,
                  autorange: false,
                  showticklabels: false,
                  ticks: '',
                  showline: false,
                },
                margin: { l: 0, r: 0, t: 0, b: 0 },
                showlegend: false,
                images: userPcaProjection
                  ? [
                      {
                        source: profileUserImage,
                        x: userPcaProjection[0],
                        y: userPcaProjection[1],
                        xref: 'x',
                        yref: 'y',
                        xanchor: 'center',
                        yanchor: 'middle',
                        sizex: imageSizeX,
                        sizey: imageSizeY,
                        sizing: 'contain',
                        opacity: 1,
                        layer: 'above',
                      },
                    ]
                  : [],
              }}
              useResizeHandler
              style={{ width: '100%', height: '100%' }}
              config={{
                responsive: true,
                scrollZoom: true,
                displayModeBar: false,
              }}
              onRelayout={handleRelayout}
            />
          )}
        </div>
      </div>
      <div className='text-center text-gray-400 text-xs p-2'>
        Die graphische Darstellung der Studiengänge ist Nährungsweise. Die Ergebnisse können von denen der Suche abweichen.
      </div>
    </Layout>
  );
};
