// FilterComponent.js
import React, { useState } from "react";
import Select from "react-select";

function FilterComponent({
  selectedKategorien,
  setSelectedKategorien,
  selectedTyp,
  setSelectedTyp,
  selectedSchwierigkeit,
  setSelectedSchwierigkeit,
}) {
  // Define options for filters
  const kategorienOptions = [
    {
      value: "Medizin & Gesundheitswesen",
      label: "Medizin & Gesundheitswesen",
    },
    { value: "Medien & Kommunikation", label: "Medien & Kommunikation" },
    { value: "Wirtschaft & Recht", label: "Wirtschaft & Recht" },
    {
      value: "Technik & Ingenieurwissenschaften",
      label: "Technik & Ingenieurwissenschaften",
    },
    {
      value: "Gesellschaftswissenschaften",
      label: "Gesellschaftswissenschaften",
    },
    { value: "Naturwissenschaften", label: "Naturwissenschaften" },
    {
      value: "Sprach- & Kulturwissenschaften",
      label: "Sprach- & Kulturwissenschaften",
    },
    { value: "Kunst & Musik", label: "Kunst & Musik" },
    { value: "Informatik & Mathematik", label: "Informatik & Mathematik" },
    {
      value: "Agrar- & Forstwissenschaften",
      label: "Agrar- & Forstwissenschaften",
    },
  ];

  const typOptions = [
    { value: "Studium", label: "Studium" },
    { value: "Ausbildung", label: "Ausbildung" },
    // Add more options as needed
  ];

  const schwierigkeitOptions = [
    { value: "Schwierig", label: "Schwierig" },
    { value: "Schwierig ausschließen", label: "Schwierig ausschließen" },
    // Add more options as needed
  ];

  // Neue Funktion zum Zurücksetzen aller Filter
  const resetAllFilters = () => {
    setSelectedKategorien([]);
    setSelectedTyp([]);
    setSelectedSchwierigkeit([]);
  };

  return (
    <div className="mb-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-2">
        {/* Kategorien Filter */}
        <div className="w-full">
          <Select
            options={kategorienOptions}
            isMulti
            placeholder="Wähle Kategorien..."
            value={selectedKategorien.map((k) => ({ value: k, label: k }))}
            onChange={(selectedOptions) =>
              setSelectedKategorien(
                selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : []
              )
            }
            className="w-full"
            classNamePrefix="select"
            styles={{
              option: (provided) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </div>

        {/* Typ Filter */}
        <div className="w-full">
          <Select
            options={typOptions}
            isMulti
            placeholder="Wähle Typ..."
            value={selectedTyp.map((k) => ({ value: k, label: k }))}
            onChange={(selectedOptions) =>
              setSelectedTyp(
                selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : []
              )
            }
            className="w-full"
            classNamePrefix="select"
            styles={{
              option: (provided) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </div>

        {/* Schwierigkeit Filter */}
        <div className="w-full">
          <Select
            options={schwierigkeitOptions}
            isMulti
            placeholder="Wähle Schwierigkeit..."
            value={selectedSchwierigkeit.map((k) => ({ value: k, label: k }))}
            onChange={(selectedOptions) =>
              setSelectedSchwierigkeit(
                selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : []
              )
            }
            className="w-full"
            classNamePrefix="select"
            styles={{
              option: (provided) => ({
                ...provided,
                color: "black",
              }),
            }}
          />
        </div>
      </div>

      {/* Reset Button - nur anzeigen, wenn mindestens ein Filter aktiv ist */}
      {(selectedKategorien.length > 0 ||
        selectedTyp.length > 0 ||
        selectedSchwierigkeit.length > 0) && (
        <div className="flex justify-end">
          <button
            onClick={resetAllFilters}
            className="text-primary hover:text-primary-dark transition-colors duration-200 text-sm"
          >
            Alle Filter löschen
          </button>
        </div>
      )}
    </div>
  );
}

export default FilterComponent;
