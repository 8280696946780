import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const AutoPopup = ({
  title = "Welcome",
  content = "This is an important message.",
  confirmText = "Understood",
  navigateText = "Go to Overview",
  onConfirm = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    onConfirm();
  };

  const handleNavigateToOverview = () => {
    setIsOpen(false);
    navigate("/overview");
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="auto-popup-dialog-title"
      aria-describedby="auto-popup-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="auto-popup-dialog-title">
        <h1>{title}</h1>
      </DialogTitle>
      <div className="flex items-center mx-4">
        <div className="relative flex-shrink-0 bg-gray-200 text-black font-bold w-16 h-16 flex items-center justify-center rounded-lg group">
          <span
            style={{
              filter: "blur(3px)",
            }}
          >
            86.45
          </span>
        </div>
        <DialogContent>
          <DialogContentText id="auto-popup-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      </div>
      <DialogActions>
        <Button
          onClick={handleClose}
          autoFocus
          disableRipple
          sx={{
            border: "2px solid #181826",
            color: "#181826",
            overflow: "visible",
            fontWeight: "bold",
          }}
        >
          {confirmText}
        </Button>
        <Button
          className="font-bold"
          onClick={handleNavigateToOverview}
          disableRipple
          sx={{
            border: "2px solid #181826",
            backgroundColor: "#181826",
            color: "white",
            overflow: "visible",
            fontWeight: "bold",
          }}
        >
          {navigateText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoPopup;
