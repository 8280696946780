// Suche.js
import React from "react";
import Select from "react-select";

function Suche({
  courseOptions,
  selectedCourse,
  setSelectedCourse,
  searchByCourseID,
  queryPinecone,
  loading,
}) {
  const handleSearch = () => {
    if (!selectedCourse || !selectedCourse.value) {
      console.log("query");
      queryPinecone();
    } else {
      console.log("suche");
      searchByCourseID();
    }
  };

  return (
    <>
      <div className="relative w-full text-primary rounded-full border-0 shadow-md">
        <Select
          className="w-full"
          options={courseOptions}
          value={selectedCourse}
          onChange={setSelectedCourse}
          placeholder="Studiengang suchen..."
          isClearable
          styles={{
            control: (provided) => ({
              ...provided,
              minHeight: "60px",
              borderRadius: "2rem",
              padding: "0 1rem",
            }),
            input: (provided) => ({
              ...provided,
              fontSize: "1.25rem",
            }),
          }}
        />
        <button
          onClick={handleSearch}
          className="absolute top-1/2 transform -translate-y-1/2 text-nowrap right-3 bg-primary text-white font-bold py-2 px-4 rounded-full transition duration-200 flex "
          disabled={loading}
        >
          <span className="mr-2">Suchen</span>
          {loading ? (
            <span className="loading loading-spinner"></span>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </button>
      </div>
    </>
  );
}

export default Suche;
