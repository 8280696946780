import data from "../../assets/default_embeddings.json";
import embeddingsLong from "../../assets/embeddings_long.json";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#185cc6",
    },
    secondary: {
      main: "#ffc144",
    },
  },
});

export function DefaultCourses() {
  const shuffledData = [...data].sort(() => 0.5 - Math.random());
  const selectedData = shuffledData.slice(0, 20);

  return (
    <div className="mt-6 w-full">
      {selectedData.map((item) => {
        const course = embeddingsLong.find(
          (course) => course.name === item.name
        );
        const description = course ? course.metadata.Beschreibung : "";

        return (
          <div
            key={item.name}
            className="p-4 pt-6 md:pt-3 shadow-lg rounded-lg mb-4 bg-white text-black flex flex-col md:flex-row relative hover:shadow-xl hover:scale-[1.01] transition duration-300"
          >
            <div className="flex-grow mr-4">
              <div className="flex items-center">
                <strong className="block">{item.name}</strong>
              </div>
              <p className="text-gray-600 my-3">{description}</p>

              <ThemeProvider theme={theme}>
                <Stack className="mt-5 flex flex-wrap" direction="row">
                  <Chip
                    label={item.metadata?.Type}
                    color="primary"
                    className="m-1"
                    size="small"
                    variant="outlined"
                    icon={<SchoolOutlinedIcon />}
                  />
                  <Chip
                    label={item.metadata?.Kategorie}
                    color="primary"
                    className="m-1"
                    size="small"
                    variant="outlined"
                    icon={<CategoryOutlinedIcon />}
                  />
                  {item.metadata?.Schwierigkeit === "Hoch" && (
                    <Chip
                      label="Schwer"
                      className="m-1"
                      color="primary"
                      size="small"
                      variant="outlined"
                    />
                  )}
                </Stack>
              </ThemeProvider>
            </div>
            {/* Mobile Version */}
            <div className="md:hidden">
              <Divider
                flexItem
                sx={{
                  margin: "1rem 0",
                  "&::before, &::after": { borderColor: "gray" },
                }}
              >
                <div className="text-gray-500 px-2">SCORE</div>
              </Divider>
              <div className="flex justify-center items-center min-w-[80px] mt-4">
                <div className="text-center font-bold text-lg">
                  {item.score ? (
                    ((1 - item.score / 18) * 100).toFixed(2)
                  ) : (
                    <span
                      style={{
                        filter: "blur(5px)",
                      }}
                    >
                      86.45
                    </span>
                  )}
                  {/* <span className="text-sm block text-gray-500">%</span> */}
                </div>
              </div>
            </div>

            {/* Desktop Version */}
            <div className="hidden md:flex">
              <Divider
                orientation="vertical"
                flexItem
                sx={{ "&::before, &::after": { borderColor: "gray" } }}
              >
                <div className="text-gray-500 h-full">
                  <span
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(180deg)",
                      display: "block",
                    }}
                  >
                    SCORE
                  </span>
                </div>
              </Divider>
              <div className="flex justify-center items-center ml-4 min-w-[120px]">
                <div className="text-center font-bold text-2xl">
                  {item.score ? (
                    ((1 - item.score / 18) * 100).toFixed(2)
                  ) : (
                    <span
                      style={{
                        filter: "blur(5px)",
                      }}
                    >
                      86.45
                    </span>
                  )}
                  {/* <span className="text-sm block text-gray-500">%</span> */}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
