export const data = [
  {
    PCA1: -0.7814204719,
    PCA2: 0.1874980547,
    name: "Aerospace Engineering",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.0065191548,
    PCA2: -0.1914763517,
    name: "Agrarmanagement",
    Kategorie: "Agrar- & Forstwissenschaften",
  },
  {
    PCA1: -0.3771707546,
    PCA2: -0.0333045967,
    name: "Agrartechnik",
    Kategorie: "Agrar- & Forstwissenschaften",
  },
  {
    PCA1: -0.0994521752,
    PCA2: -0.0232285378,
    name: "Agrarwirtschaft",
    Kategorie: "Agrar- & Forstwissenschaften",
  },
  {
    PCA1: -0.2322665215,
    PCA2: -0.0659617254,
    name: "Agrarwissenschaften",
    Kategorie: "Agrar- & Forstwissenschaften",
  },
  {
    PCA1: -0.5440931881,
    PCA2: 0.2520848427,
    name: "Allgemeine Informatik",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.4139202123,
    PCA2: 0.1862729891,
    name: "Angewandte Informatik",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.5708655727,
    PCA2: 0.1459873996,
    name: "Angewandte Mathematik",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: 0.2116707499,
    PCA2: 0.582862019,
    name: "Architektur",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: -0.5917427888,
    PCA2: 0.1686824947,
    name: "Automatisierungstechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.6194989733,
    PCA2: 0.1120795333,
    name: "Automobilentwicklung",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.2081588299,
    PCA2: -0.0933342489,
    name: "Automobilwirtschaft",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.5650939045,
    PCA2: 0.2030925211,
    name: "Automotive Engineering",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.6906036668,
    PCA2: 0.3142505156,
    name: "Autonome Systeme",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.0069898189,
    PCA2: -0.2892199043,
    name: "Aviation Management",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.5426706331,
    PCA2: 0.1799590056,
    name: "Bauingenieurwesen",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.5039714852,
    PCA2: -0.0752208464,
    name: "Bauingenieurwesen - Hochbau und konstruktiver Ingenieurbau",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.5426706331,
    PCA2: 0.1799590056,
    name: "Bauingenieurwesen - Wasser- und Umwelttechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.4396544595,
    PCA2: -0.1186401581,
    name: "Bibliotheks- und Informationsmanagement",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.5754948239,
    PCA2: 0.1839180184,
    name: "Biochemie",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: -0.5493318127,
    PCA2: 0.2998725318,
    name: "Bioinformatik",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.0414707331,
    PCA2: 0.2344297349,
    name: "Biologie",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: -0.4740583771,
    PCA2: 0.2196687391,
    name: "Biomedical Engineering",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4908358567,
    PCA2: 0.3134977523,
    name: "Biomedizinische Technik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.3022263712,
    PCA2: 0.0971113265,
    name: "Biomedizinische Wissenschaften",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: -0.3918675081,
    PCA2: 0.3251867218,
    name: "Bionik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4528618839,
    PCA2: 0.3381787524,
    name: "Biotechnologie",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.6066567464,
    PCA2: 0.194355762,
    name: "Chemie",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: -0.5302502862,
    PCA2: 0.0924363374,
    name: "Computer Science (Informatik)",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.313079715,
    PCA2: 0.3034874962,
    name: "Data Science",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: 0.4463175847,
    PCA2: 0.5488099866,
    name: "Design (Produkt-/Industriedesign)",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: 0.4447135425,
    PCA2: 0.3093724735,
    name: "Digital Humanities",
    Kategorie: "Sprach- & Kulturwissenschaften",
  },
  {
    PCA1: -0.6475430775,
    PCA2: 0.0453298769,
    name: "Elektrotechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.1904987877,
    PCA2: 0.3407032181,
    name: "Energie- und Umweltmanagement",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.4774966954,
    PCA2: 0.3712972548,
    name: "Entrepreneurship und Innovationsmanagement",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.3127661334,
    PCA2: 0.321536528,
    name: "Erneuerbare Energien",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.1962917096,
    PCA2: 0.0973668068,
    name: "Ern\u00e4hrungswissenschaften (\u00d6kotrophologie)",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.7498284346,
    PCA2: 0.0440719687,
    name: "Erziehungswissenschaften (P\u00e4dagogik)",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.5650939045,
    PCA2: 0.2030925211,
    name: "Fahrzeugtechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.2147265347,
    PCA2: -0.0234586013,
    name: "Forstwissenschaften",
    Kategorie: "Agrar- & Forstwissenschaften",
  },
  {
    PCA1: 0.3976502426,
    PCA2: 0.5768700167,
    name: "Game Design",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: 0.3212219454,
    PCA2: 0.0923883709,
    name: "Geographie",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.4052558104,
    PCA2: 0.3611629351,
    name: "Geowissenschaften",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: 0.6995236876,
    PCA2: 0.3462882245,
    name: "Germanistik",
    Kategorie: "Sprach- & Kulturwissenschaften",
  },
  {
    PCA1: 0.6419990919,
    PCA2: 0.2130443899,
    name: "Geschichte",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.3794396371,
    PCA2: -0.2831248736,
    name: "Gesundheitsmanagement",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: 0.2972276853,
    PCA2: -0.2245271025,
    name: "Gesundheitswissenschaften",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: 0.6382712655,
    PCA2: 0.085071164,
    name: "Grundschul-Lehramt",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.5303142558,
    PCA2: 0.0665603621,
    name: "Gymnasial-Lehramt",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.361273355,
    PCA2: -0.0079592679,
    name: "Hebammenkunde",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: -0.2466896064,
    PCA2: 0.0664344057,
    name: "IT-Management",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.4216234852,
    PCA2: 0.141395283,
    name: "IT-Sicherheit",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.5882911739,
    PCA2: 0.2779418164,
    name: "Informatik",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.6233184145,
    PCA2: 0.21705384,
    name: "Ingenieurinformatik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.7381085009,
    PCA2: 0.0564337834,
    name: "Internationale Beziehungen",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.3140129269,
    PCA2: 0.0560649497,
    name: "International Wirtschaft",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.8174183363,
    PCA2: 0.4208840406,
    name: "Journalismus",
    Kategorie: "Medien & Kommunikation",
  },
  {
    PCA1: 0.4720746049,
    PCA2: -0.2735433603,
    name: "Jura",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.2541718203,
    PCA2: 0.4255371212,
    name: "Kognitive Neurowissenschaften",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: 0.7514460563,
    PCA2: 0.5332245024,
    name: "Kommunikationsdesign",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: 0.7719010128,
    PCA2: 0.1684357463,
    name: "Kommunikationsmanagement",
    Kategorie: "Medien & Kommunikation",
  },
  {
    PCA1: 0.8125786989,
    PCA2: 0.3587551188,
    name: "Kulturwissenschaften",
    Kategorie: "Sprach- & Kulturwissenschaften",
  },
  {
    PCA1: 0.5615572919,
    PCA2: 0.3017876712,
    name: "Linguistik (Sprachwissenschaft)",
    Kategorie: "Sprach- & Kulturwissenschaften",
  },
  {
    PCA1: 0.0533289664,
    PCA2: -0.3055248069,
    name: "Logistikmanagement",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.6321365316,
    PCA2: 0.1350852891,
    name: "Maschinenbau",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.6592615441,
    PCA2: 0.1977013469,
    name: "Materialwissenschaften",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.2936276231,
    PCA2: 0.002908235,
    name: "Mathematik",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.5650939045,
    PCA2: 0.2030925211,
    name: "Mechatronik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.8298512841,
    PCA2: 0.4629642588,
    name: "Medien- und Kommunikationswissenschaft",
    Kategorie: "Medien & Kommunikation",
  },
  {
    PCA1: 0.159426861,
    PCA2: 0.3872588841,
    name: "Medieninformatik",
    Kategorie: "Medien & Kommunikation",
  },
  {
    PCA1: 0.7905967651,
    PCA2: 0.4241367005,
    name: "Medienmanagement",
    Kategorie: "Medien & Kommunikation",
  },
  {
    PCA1: 0.6572541267,
    PCA2: 0.513943375,
    name: "Medienpsychologie",
    Kategorie: "Medien & Kommunikation",
  },
  {
    PCA1: 0.2219364397,
    PCA2: 0.4907536632,
    name: "Medientechnik",
    Kategorie: "Medien & Kommunikation",
  },
  {
    PCA1: 0.0304823685,
    PCA2: 0.1029705915,
    name: "Medizin",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: 0.3336522611,
    PCA2: -0.2594111676,
    name: "Medizinmanagement",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: -0.3874513534,
    PCA2: 0.022568893,
    name: "Medizintechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4981314281,
    PCA2: 0.3830393613,
    name: "Meteorologie",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: 0.821497412,
    PCA2: 0.4134139642,
    name: "Musik",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: -0.3268956941,
    PCA2: 0.2937269916,
    name: "Nachhaltige Energietechnologien",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.3615128032,
    PCA2: 0.185881761,
    name: "Nachhaltigkeitsmanagement",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.5839001049,
    PCA2: 0.3248196274,
    name: "Nanotechnologie",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.6740074065,
    PCA2: -0.3080958695,
    name: "Personalmanagement",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.3927045596,
    PCA2: -0.1863694875,
    name: "Pflegewissenschaften",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: -0.2462061515,
    PCA2: 0.0376647133,
    name: "Pharmazie",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: 0.7228867776,
    PCA2: 0.4638230202,
    name: "Philosophie",
    Kategorie: "Sprach- & Kulturwissenschaften",
  },
  {
    PCA1: -0.5974829659,
    PCA2: 0.3115599751,
    name: "Physik",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: 0.6685278568,
    PCA2: 0.007352648,
    name: "Politikwissenschaft",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.5503917913,
    PCA2: 0.139860332,
    name: "Psychologie",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.380213704,
    PCA2: 0.0269428677,
    name: "Public Health",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.7129191353,
    PCA2: 0.2154257586,
    name: "Robotik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4062169393,
    PCA2: 0.2311506952,
    name: "Software Engineering",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: 0.8352669512,
    PCA2: -0.1082761482,
    name: "Soziale Arbeit",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.7080979247,
    PCA2: 0.0460773889,
    name: "Soziologie",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.4283313546,
    PCA2: -0.1682656107,
    name: "Sportmanagement",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.2272258901,
    PCA2: -0.2351885089,
    name: "Sportwissenschaft",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.0225744129,
    PCA2: -0.052095692,
    name: "Supply Chain Management",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.6415667015,
    PCA2: 0.1375770404,
    name: "Technische Physik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.6635744119,
    PCA2: 0.0118739151,
    name: "Tourismus und Eventmanagement",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.2876392776,
    PCA2: 0.1972477783,
    name: "Umweltmanagement",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: 0.3481374819,
    PCA2: 0.2569313501,
    name: "Umweltpsychologie",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.2771409992,
    PCA2: 0.0682636104,
    name: "Umwelttechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.0832382484,
    PCA2: 0.2882241209,
    name: "Umweltwissenschaften",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: -0.6483650811,
    PCA2: 0.2245137415,
    name: "Verfahrenstechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.0586831468,
    PCA2: -0.1832828577,
    name: "Verkehrsplanung",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.236175038,
    PCA2: -0.2739734337,
    name: "Verkehrswesen",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4834084845,
    PCA2: -0.1519156566,
    name: "Versorgungs- und Entsorgungstechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.2572136706,
    PCA2: -0.1368617194,
    name: "Volkswirtschaftslehre (VWL)",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.1084999272,
    PCA2: 0.0627346492,
    name: "Wirtschaftsinformatik",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.0527318874,
    PCA2: 0.1957129641,
    name: "Wirtschaftsinformatik und Digitale Transformation",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.2028647988,
    PCA2: 0.061023107,
    name: "Wirtschaftsingenieurwesen",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4500888728,
    PCA2: 0.1370733258,
    name: "Wirtschaftsingenieurwesen - Elektrotechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4198105401,
    PCA2: 0.0812938195,
    name: "Wirtschaftsingenieurwesen - Maschinenbau",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.2463038318,
    PCA2: 0.0694657775,
    name: "Wirtschaftsingenieurwesen - Umwelttechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.1520630609,
    PCA2: 0.0432183102,
    name: "Wirtschaftsmathematik",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.5750850745,
    PCA2: -0.018724837,
    name: "Wirtschaftspsychologie",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.4158050507,
    PCA2: -0.3616868919,
    name: "Wirtschaftsrecht",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.1206092382,
    PCA2: -0.1242956896,
    name: "Zahnmedizin",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: -0.3833695219,
    PCA2: -0.3339976701,
    name: "Anlagenmechaniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.2664331271,
    PCA2: -0.2031285229,
    name: "Anlagenmechaniker:in f\u00fcr Sanit\u00e4r-, Heizungs- und Klimatechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.3833695219,
    PCA2: -0.3339976701,
    name: "Anlagenmechaniker/in \u2013 Rohrsystemtechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.0658265994,
    PCA2: -0.1616572958,
    name: "Augenoptiker/in",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.3194138744,
    PCA2: -0.1637385625,
    name: "Automatenfachmann/-frau",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.2547185494,
    PCA2: -0.4204878293,
    name: "Automobilkaufmann/-frau",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.231899257,
    PCA2: -0.2550326482,
    name: "B\u00e4cker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.2803718072,
    PCA2: -0.3566825922,
    name: "Bankkaufmann/-frau",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.2776897201,
    PCA2: -0.4244508418,
    name: "Bauger\u00e4tef\u00fchrer/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4342218172,
    PCA2: -0.3172197399,
    name: "Beton- und Stahlbetonbauer:in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.3820921386,
    PCA2: 0.0349458496,
    name: "Biologielaborant/in",
    Kategorie: "Naturwissenschaften",
  },
  {
    PCA1: 0.1251638994,
    PCA2: -0.3269645379,
    name: "Bodenleger/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.6733026849,
    PCA2: -0.0540060089,
    name: "Buchh\u00e4ndler:in",
    Kategorie: "Sprach- & Kulturwissenschaften",
  },
  {
    PCA1: -0.5222295015,
    PCA2: 0.1483468952,
    name: "Chemielaborant/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.5738516894,
    PCA2: -0.1297554433,
    name: "Chemikant/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.2250849224,
    PCA2: -0.4277964267,
    name: "Dachdecker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.1140911936,
    PCA2: -0.1968274302,
    name: "Drogist:in",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.4751831769,
    PCA2: -0.2053505169,
    name: "Elektroanlagenmonteur/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.5156684697,
    PCA2: -0.126407559,
    name: "Elektroniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.5516380163,
    PCA2: -0.139468161,
    name: "Elektroniker/in f\u00fcr Automatisierungstechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4813579962,
    PCA2: -0.1887742915,
    name: "Elektroniker/in f\u00fcr Betriebstechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4890612691,
    PCA2: -0.2336519976,
    name: "Elektroniker/in f\u00fcr Energie- und Geb\u00e4udetechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.5789719968,
    PCA2: 0.1747910404,
    name: "Elektroniker/in f\u00fcr Informations- und Systemtechnik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4139202123,
    PCA2: 0.1862729891,
    name: "Fachinformatiker/in",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.4399650331,
    PCA2: 0.0469431126,
    name: "Fachinformatiker/in f\u00fcr Anwendungsentwicklung",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.290019041,
    PCA2: 0.0976817588,
    name: "Fachinformatiker/in f\u00fcr Systemintegration",
    Kategorie: "Informatik & Mathematik",
  },
  {
    PCA1: -0.079236993,
    PCA2: -0.3399879218,
    name: "Fachkraft f\u00fcr Lagerlogistik",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.1115322986,
    PCA2: -0.4029647253,
    name: "Fachlagerist/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.0549432182,
    PCA2: -0.288020278,
    name: "Fahrzeuglackierer/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4620141827,
    PCA2: -0.1966853022,
    name: "Feinwerkmechaniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4505097047,
    PCA2: -0.2880757483,
    name: "Fertigungsmechaniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.0388964027,
    PCA2: -0.447873451,
    name: "Fleischer:in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.0454216998,
    PCA2: -0.2750716563,
    name: "Fliesenleger/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.7903454299,
    PCA2: 0.2976106707,
    name: "Florist/in",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: 0.7098205935,
    PCA2: 0.4239102747,
    name: "Fotograf/in",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: 0.7390030793,
    PCA2: -0.0495974733,
    name: "Friseur / Friseurin",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: 0.3704358775,
    PCA2: -0.0294885893,
    name: "G\u00e4rtner/in",
    Kategorie: "Agrar- & Forstwissenschaften",
  },
  {
    PCA1: 0.1432765438,
    PCA2: -0.4300067211,
    name: "Geb\u00e4udereiniger/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.2185833803,
    PCA2: -0.4193445449,
    name: "Ger\u00fcstbauer/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.0806282085,
    PCA2: -0.366504269,
    name: "Glaser/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.3097353238,
    PCA2: 0.254830682,
    name: "Goldschmied/in",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: 0.4317103043,
    PCA2: -0.2862233256,
    name: "Handelsfachwirt/in",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.5043571532,
    PCA2: -0.2929143016,
    name: "Hauswirtschafter/in",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.1941132706,
    PCA2: -0.238556231,
    name: "H\u00f6rakustiker/in",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: 0.6279676571,
    PCA2: -0.2996692614,
    name: "Hotelfachmann/-frau",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.2010737803,
    PCA2: -0.3875878858,
    name: "Industriekaufmann/-frau",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.3878733226,
    PCA2: -0.2518866111,
    name: "Industriemechaniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.2047052881,
    PCA2: -0.3910430642,
    name: "Kanalbauer/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.3455521435,
    PCA2: -0.3892994754,
    name: "Karosserie- und Fahrzeugbaumechaniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.5960895099,
    PCA2: -0.3375205034,
    name: "Kauffrau/-mann f\u00fcr B\u00fcromanagement",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.8449354421,
    PCA2: 0.0845559379,
    name: "Kauffrau/-mann f\u00fcr Marketingkommunikation",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.1325155061,
    PCA2: -0.4553726782,
    name: "Kauffrau/-mann f\u00fcr Spedition und Logistikdienstleistung",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.5894147543,
    PCA2: -0.2305538147,
    name: "Kaufmann/-frau im Einzelhandel",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.3660856916,
    PCA2: -0.3727368944,
    name: "Kfz-Mechatroniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.3626902221,
    PCA2: -0.1810521792,
    name: "Koch/K\u00f6chin",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: 0.6633808166,
    PCA2: -0.0234921546,
    name: "Kosmetiker/in",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: -0.1347285612,
    PCA2: -0.1220937229,
    name: "Landwirt/in",
    Kategorie: "Agrar- & Forstwissenschaften",
  },
  {
    PCA1: 0.3127032639,
    PCA2: 0.0848613473,
    name: "Maler/in und Lackierer/in",
    Kategorie: "Kunst & Musik",
  },
  {
    PCA1: -0.1589700347,
    PCA2: -0.3798558919,
    name: "Maurer/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.4805121243,
    PCA2: -0.01559611,
    name: "Mechatroniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.5751335155,
    PCA2: 0.5531318841,
    name: "Mediengestalter/in Digital und Print",
    Kategorie: "Medien & Kommunikation",
  },
  {
    PCA1: -0.418893277,
    PCA2: -0.28487231,
    name: "Metallbauer/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.1869979408,
    PCA2: -0.0961178324,
    name: "Milchtechnologe/-technologin",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.3096430564,
    PCA2: -0.1886733607,
    name: "Notfallsanit\u00e4ter/in",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: 0.0626463289,
    PCA2: -0.1734992434,
    name: "Orthop\u00e4dietechnik-Mechaniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.5780723143,
    PCA2: -0.2729102665,
    name: "Personaldienstleistungskaufmann/-frau",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.3264046961,
    PCA2: 0.0096081731,
    name: "Pharmakant/in",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: 0.4681148426,
    PCA2: -0.2454529383,
    name: "Pflegefachmann/-frau",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: 0.2254080187,
    PCA2: -0.418935776,
    name: "Polizist:in (mittlerer Dienst)",
    Kategorie: "Gesellschaftswissenschaften",
  },
  {
    PCA1: 0.5445185843,
    PCA2: -0.3206786901,
    name: "Rechtsanwaltsfachangestellte/r",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.0086912798,
    PCA2: -0.2416727238,
    name: "Schornsteinfeger/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.3112020116,
    PCA2: -0.1829121104,
    name: "Steuerfachangestellte/r",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.2362954318,
    PCA2: -0.5195221273,
    name: "Stra\u00dfenbauer/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.028203754,
    PCA2: -0.0675080774,
    name: "Tischler/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.683610301,
    PCA2: -0.2001949772,
    name: "Tourismuskaufmann/-frau",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: 0.6401091213,
    PCA2: -0.3967408073,
    name: "Verk\u00e4ufer/in",
    Kategorie: "Wirtschaft & Recht",
  },
  {
    PCA1: -0.431848203,
    PCA2: -0.1975278131,
    name: "Werkzeugmechaniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: 0.2356119702,
    PCA2: -0.316583099,
    name: "Zahnmedizinische/r Fachangestellte/r",
    Kategorie: "Medizin & Gesundheitswesen",
  },
  {
    PCA1: -0.1291608792,
    PCA2: -0.16163352,
    name: "Zahntechniker/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
  {
    PCA1: -0.0931521683,
    PCA2: -0.3676787613,
    name: "Zimmerer/in",
    Kategorie: "Technik & Ingenieurwissenschaften",
  },
];

export const pca_components = [[ 0.10953503, -0.06501542,  0.1595045 ,  0.03727977,  0.05605255,
  0.12283331,  0.23413078, -0.31161923,  0.13878092, -0.04807624,
  0.26085166,  0.07703273, -0.221034  ,  0.09108897, -0.44197986,
 -0.52604798,  0.3016598 ,  0.26648884],
[-0.03285435, -0.08451882,  0.03664925, -0.00112611,  0.4586285 ,
  0.40908836, -0.03129411,  0.10437744,  0.28301481, -0.23498346,
 -0.08931872,  0.44877706,  0.2513338 , -0.08093098,  0.25856974,
  0.03345585, -0.00842511,  0.34410026]];

export const pca_means = [0.63807107, 0.76218274, 0.55532995, 0.38781726, 0.64796954,
  0.57182741, 0.61040609, 0.64492386, 0.58147208, 0.58984772,
  0.60253807, 0.59492386, 0.70203046, 0.54974619, 0.4680203 ,
  0.60304569, 0.52588832, 0.32538071];
