import React, { useEffect, useState, useRef } from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const DynamicRadarChart = ({ onTextChange }) => {
  const [data, setData] = useState({
    labels: [
      "Gewissenhaftigkeit",
      "Extrov.",
      "Offenheit",
      "Verträglickeit",
      "Neuro.",
    ],
    datasets: [
      {
        label: "Dynamic Data",
        data: [0.65, 0.59, 0.9, 0.81, 0.56], // Values between 0 and 1
        backgroundColor: "rgba(255, 172, 28, 0.2)",
        borderColor: "rgba(255, 172, 132, 1)",
        pointBackgroundColor: "rgba(255, 172, 28, 1)",
        pointBorderColor: "rgba(255, 172, 28, 1)",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(255, 172, 28, 1)",
        borderWidth: 5,
      },
    ],
  });

  const [text, setText] = useState("Speed"); // Initial text corresponding to the first label
  const phrases = [
    "Medizin",
    "Informatik",
    "Kunsthistorik",
    "Maschinenbau",
    "Psychologie",
    "Jura",
    "Biologie",
    "BWL",
    "Architektur",
    "Pädagogik",
  ];
  const [index, setIndex] = useState(0);
  const chartRef = useRef(null);
  const [showText, setShowText] = useState(false);

  const getRandomValue = () => Math.random().toFixed(2); // Values between 0 and 1

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => ({
          ...dataset,
          data: [
            getRandomValue(),
            getRandomValue(),
            getRandomValue(),
            getRandomValue(),
            getRandomValue(),
          ],
        })),
      }));
      // Update the text label in sequence
      setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
      const newText = phrases[index];
      setText(newText);
      onTextChange(newText);
      setShowText(true);
      setTimeout(() => setShowText(false), 500);
    }, 3000); // Update every second

    return () => clearInterval(interval);
  }, [index, phrases, onTextChange]);

  const chartStyle = { width: "100%", height: "100%" }; // Increase size for sharpness

  return (
    <div ref={chartRef} style={chartStyle}>
      <Radar
        data={data}
        options={{
          //devicePixelRatio: window.devicePixelRatio || 2, // Ensure high resolution
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
            tooltip: {
              enabled: true, // Optionally disable tooltips
            },
          },
          scales: {
            r: {
              min: 0,
              max: 1,
              ticks: {
                display: false, // Remove the tick labels
                stepSize: 0.33, // Tick interval of 0.2
                backdropColor: "transparent", // Make the tick labels transparent
                font: {
                  size: 14, // Increase font size for clarity
                },
              },
              pointLabels: {
                display: true, // Remove the axis labels
                padding: 1,
                font: {
                  size: 12, // Increase font size for clarity
                },
              },
              angleLines: {
                color: "rgba(255, 255, 255, 0.5)", // Change the angle lines to white with 50% opacity
                lineWidth: 2, // Make the angle lines thicker
              },
              grid: {
                color: "rgba(255, 255, 255, 0.25)", // Change the grid lines to white with 50% opacity
                lineWidth: 2, // Make the grid lines thicker
              },
            },
          },
        }}
      />
      {/* <div
        style={{
          textAlign: "center",
          color: "white",
          fontSize: "1.2em",
          transition: "opacity 0.5s ease-in-out",
          opacity: showText ? 1 : 0,
        }}
      >
        {text}
      </div> */}
    </div>
  );
};

export default DynamicRadarChart;

export function ChartTest() {
  const chartStyle = { width: "800px", height: "800px" };
  return (
    <div style={chartStyle}>
      <DynamicRadarChart />
    </div>
  );
}
