import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

const SkeletonItem = () => (
  <div className="p-4 pt-6 md:pt-3 shadow-lg rounded-lg mb-4 bg-white text-black flex flex-col md:flex-row relative">
    <div className="flex-grow md:mr-4">
      <div className="mr-1">
        <div className="flex items-center">
          <Skeleton variant="text" width={300} height={30} />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            style={{ marginLeft: "10px" }}
          />
        </div>
        <Skeleton
          variant="text"
          width="90%"
          height={60}
          style={{ marginTop: "10px" }}
        />
        <Stack className="mt-5 flex flex-wrap" direction="row" spacing={1}>
          <Skeleton variant="rounded" width={100} height={32} />
          <Skeleton variant="rounded" width={120} height={32} />
          <Skeleton variant="rounded" width={80} height={32} />
        </Stack>
      </div>
    </div>
    <div className="hidden md:flex">
      <Divider orientation="vertical" flexItem>
        <Skeleton variant="text" width={20} height={100} />
      </Divider>
      <div className="flex justify-center items-center ml-4 min-w-[120px]">
        <Skeleton variant="text" width={80} height={40} />
      </div>
    </div>
  </div>
);

export function SkeletonLoader() {
  return (
    <div className="mt-6 w-full">
      {[1, 2, 3].map((item) => (
        <SkeletonItem key={item} />
      ))}
    </div>
  );
}
