import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Home } from "./pages/Home";
import { Overview } from "./pages/Overview/Overview";
import { Evaluation } from "./pages/Auswertung/Evaluation";
import { LogIn } from "./pages/Login/LogIn";
import { Personality } from "./pages/Personality.js";
import { Layout } from "./pages/Layout.js";
import { Impressum } from "./pages/Impressum.js";
import { Interessen } from "./pages/Interessen.js";
import { Empathie } from "./pages/Empathie.js";
import { Prioritäten } from "./pages/Prioritäten.js";
import { Kreativiteat } from "./pages/Kreativiteat.js";
import { Geschäftsbedingungen } from "./pages/Geschäftsbedingungen.js";
import { Registrieren } from "./pages/Login/Registrieren.js";
import { AuswertungButton } from "./pages/Auswertung/AuswertungButton.js";
import { Zahlenreihen } from "./pages/Zahlenreihen.js";
import { Sprachtest } from "./pages/Sprache.js";
import { Datenschutzvereinbarung } from "./pages/Datenschutzvereinbarung.js";
import { AboutUs } from "./pages/AboutUs.js";
import BlogPost from "./pages/Blog/BlogPost.js";
import { BlogÜbersicht } from "./pages/Blog/BlogÜbersicht.js";
import { ChartProvider } from "./pages/Auswertung/ChartContext.js";
import { Widerrufsbelehrung } from "./pages/Widerrufsbelehrung.js";
import { BeispielAuswertung } from "./pages/BeispielAuswertung.js";
import { CaseStudy } from "./pages/CaseStudy.js";
import { ChartTest } from "./components/randomRadarCharts.js";
import { PersSearch } from "./pages/FreeProduct/PersSearch.js";
import { Map } from "./pages/Map/Map.js";

function App() {
  return (
    <ChartProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/evaluation" element={<Evaluation />} />
        <Route path="/Login" element={<LogIn />} />
        <Route path="/Personality" element={<Personality />} />
        <Route path="/Layout" element={<Layout />} />
        <Route path="/Impressum" element={<Impressum />} />
        <Route path="/Widerrufsbelehrung" element={<Widerrufsbelehrung />} />
        <Route
          path="/Datenschutzvereinbarung"
          element={<Datenschutzvereinbarung />}
        />
        <Route path="/Interessen" element={<Interessen />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Empathie" element={<Empathie />} />
        <Route path="/Prioritäten" element={<Prioritäten />} />
        <Route path="/Kreativiteat" element={<Kreativiteat />} />
        <Route path="/Zahlenreihen" element={<Zahlenreihen />} />
        <Route path="/Sprache" element={<Sprachtest />} />
        <Route
          path="/Geschäftsbedingungen"
          element={<Geschäftsbedingungen />}
        />
        <Route path="/Registrieren" element={<Registrieren />} />
        <Route path="/Beispiel" element={<BeispielAuswertung />} />
        <Route path="/Auswertung" element={<AuswertungButton />} />
        <Route path="/Blog" element={<BlogÜbersicht />} />
        <Route path="/:postTitle" element={<BlogPost />} />
        <Route path="/CaseStudy" element={<CaseStudy />} />
        <Route path="/ChartTest" element={<ChartTest />} />
        <Route path="/suche" element={<PersSearch />} />
        <Route path="/karte" element={<Map />} />
      </Routes>
    </ChartProvider>
  );
}

export default App;
