import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import embeddingsLong from "../../assets/embeddings_long.json"; // Import the JSON file
import Divider from "@mui/material/Divider";

import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../../firebase/firebaseConfig"; // Adjust the import based on your project structure
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#185cc6",
    },
    secondary: {
      main: "#ffc144",
    },
  },
});

const calculateDeviations = (queryVector, resultVector) => {
  if (
    !queryVector ||
    !resultVector ||
    queryVector.length !== resultVector.length
  ) {
    return []; // Return an empty array if no queryVector or resultVector
  }
  const deviations = queryVector.map((qVal, index) => {
    const rVal = resultVector[index];
    const deviation = qVal - rVal;
    return { index, deviation };
  });

  // Sort by the absolute value of the deviation
  return deviations.sort(
    (a, b) => Math.abs(b.deviation) - Math.abs(a.deviation)
  );
};

const dimensionNames = [
  "Verträglichkeit",
  "Gewissenhaftigkeit",
  "Extraversion",
  "Neurotizismus",
  "Offenheit",
  "Kreativität",
  "Empathische Fähigkeiten",
  "numerische Intelligenz",
  "Sprache",
  "Koordination",
  "Helfen",
  "Kreieren",
  "Analysieren",
  "Verhandeln",
  "Naturwissenschaft",
  "Technik",
  "Gesellschaft",
  "Künste",
  "Guter Zweck",
  "Karrieremöglichkeiten",
  "Status",
  "Arbeit im Freien",
  "Work Life Balance",
  "Arbeit mit Menschen",
  "Jobsicherheit",
  "intellektuelle Herausforderung",
  "Eigenverantwortung",
  "Geographische Mobilität",
];

const calculateEuclideanDistance = (queryVector, resultVector) => {
  if (
    !queryVector ||
    !resultVector ||
    queryVector.length !== resultVector.length
  ) {
    return "95.36"; // Verwende die feste Zahl
  }

  const euclideanDistance = Math.sqrt(
    queryVector.reduce((sum, qVal, index) => {
      const rVal = resultVector[index];
      return sum + Math.pow(qVal - rVal, 2);
    }, 0)
  );

  // Normalisiere durch 18, subtrahiere von 1 und runde auf 2 Dezimalstellen
  const normalizedDistance = (1 - euclideanDistance / 18) * 100;
  return normalizedDistance.toFixed(2);
};

// Füge eine CSS-Klasse für den Unschärfeeffekt hinzu
const blurredStyle = {
  filter: "blur(5px)", // Passe den Wert an, um die gewünschte Unschärfe zu erreichen
};

export function SearchCourses({ result, queryVector }) {
  const navigate = useNavigate();
  const [uid, setUid] = useState(null);
  const [likes, setLikes] = useState([]);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  // Function to encode itemId
  const encodeItemId = (id) => {
    return id.replace(/\//g, "_"); // Replace all slashes with underscores
  };

  // Fetch user's likes from Firestore
  useEffect(() => {
    if (uid) {
      const likesRef = collection(db, "users", uid, "likes");
      const unsubscribeLikes = onSnapshot(likesRef, (snapshot) => {
        const likesList = snapshot.docs.map((doc) => doc.id);
        setLikes(likesList);
      });

      return () => unsubscribeLikes();
    }
  }, [uid]);

  // Handle like/unlike functionality
  const handleLike = async (itemId) => {
    if (!uid) {
      navigate("/login");
      return;
    }

    const encodedItemId = encodeItemId(itemId);
    const likeRef = doc(db, "users", uid, "likes", encodedItemId);

    try {
      if (likes.includes(encodedItemId)) {
        // Remove the like
        console.log("removed");
        await deleteDoc(likeRef);
      } else {
        // Add the like
        console.log("added");
        await setDoc(likeRef, { name: encodedItemId });
      }
    } catch (error) {
      console.error("Error updating like:", error);
      // Optionally, display an error message to the user
    }
  };

  return (
    <div className="mt-6 w-full">
      {result.map((item, index) => {
        const deviations = queryVector
          ? calculateDeviations(queryVector, item.values)
          : [];
        const isLiked = likes.includes(encodeItemId(item.id));

        const course = embeddingsLong.find((course) => course.name === item.id);
        const description = course ? course.metadata.Beschreibung : "";

        return (
          <div
            key={item.id}
            className="p-4 pt-6 md:pt-3 shadow-lg rounded-lg mb-4 bg-white text-black flex flex-col md:flex-row relative hover:shadow-xl hover:scale-[1.01] transition duration-300"
          >
            <div className="flex-grow md:mr-4">
              {index === 0 && (
                <div className="absolute right-4 -top-5 md:-top-4 font-semibold text-white">
                  <ThemeProvider theme={theme}>
                    <Chip
                      label="Perfect Match"
                      className="shadow"
                      color="secondary"
                    />
                  </ThemeProvider>
                </div>
              )}

              <div className="mr-1">
                <div className="flex items-center">
                  <strong className="block">{item.id}</strong>
                  {/* Like button */}
                  <IconButton
                    aria-label={isLiked ? "favorite" : "favorite border"}
                    onClick={() => handleLike(item.id)}
                    color="error"
                  >
                    {isLiked ? (
                      <FavoriteRoundedIcon />
                    ) : (
                      <FavoriteBorderRoundedIcon />
                    )}
                  </IconButton>
                </div>
                <p className="text-gray-600 my-3">{description}</p>
                {queryVector && (
                  <>
                    <strong className="block mt-2">Abweichungen:</strong>
                    <span>
                      {deviations
                        .slice(0, 3)
                        .map((dev) => dimensionNames[dev.index])
                        .join(", ")}
                    </span>
                  </>
                )}

                <ThemeProvider theme={theme}>
                  <Stack className="mt-5 flex flex-wrap" direction="row">
                    <Chip
                      label={item.metadata?.Type}
                      color="primary"
                      className="m-1"
                      size="small"
                      variant="outlined"
                      icon={<SchoolOutlinedIcon />}
                    />
                    <Chip
                      label={item.metadata?.Kategorie}
                      color="primary"
                      className="m-1"
                      size="small"
                      variant="outlined"
                      icon={<CategoryOutlinedIcon />}
                    />
                    {item.metadata?.Schwierigkeit === "Hoch" && (
                      <Chip
                        label="Schwer"
                        className="m-1"
                        color="primary"
                        size="small"
                        variant="outlined"
                        // icon={<PriorityHighOutlinedIcon />}
                      />
                    )}
                  </Stack>
                </ThemeProvider>
              </div>
            </div>

            {/* Mobile Version */}
            <div className="md:hidden">
              <Divider
                flexItem
                sx={{
                  margin: "1rem 0",
                  "&::before, &::after": { borderColor: "gray" },
                }}
              >
                <div className="text-gray-500 px-2">SCORE</div>
              </Divider>
              <div className="flex justify-center items-center min-w-[80px] mt-4">
                <div className="text-center font-bold text-lg">
                  {queryVector && !queryVector.includes(null) ? (
                    item.score ? (
                      ((1 - item.score / 18) * 100).toFixed(2)
                    ) : (
                      calculateEuclideanDistance(queryVector, item.values)
                    )
                  ) : (
                    <span style={blurredStyle}>95.36</span>
                  )}
                  {/* <span className="text-sm block text-gray-500">%</span> */}
                </div>
              </div>
            </div>

            {/* Desktop Version */}
            <div className="hidden md:flex">
              <Divider
                orientation="vertical"
                flexItem
                sx={{ "&::before, &::after": { borderColor: "gray" } }}
              >
                <div className="text-gray-500 h-full">
                  <span
                    style={{
                      writingMode: "vertical-lr",
                      transform: "rotate(180deg)",
                      display: "block",
                    }}
                  >
                    SCORE
                  </span>
                </div>
              </Divider>
              <div className="flex justify-center items-center ml-4 min-w-[120px]">
                <div className="text-center font-bold text-2xl">
                  {queryVector && !queryVector.includes(null) ? (
                    item.score ? (
                      ((1 - item.score / 18) * 100).toFixed(2)
                    ) : (
                      calculateEuclideanDistance(queryVector, item.values)
                    )
                  ) : (
                    <span style={blurredStyle}>95.36</span>
                  )}
                  {/* <span className="text-sm block text-gray-500">%</span> */}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
