export const courseList = [
    "Aerospace Engineering",
    "Agrarmanagement",
    "Agrartechnik",
    "Agrarwirtschaft",
    "Agrarwissenschaften",
    "Allgemeine Informatik",
    "Angewandte Informatik",
    "Angewandte Mathematik",
    "Anlagenmechaniker:in für Sanitär-, Heizungs- und Klimatechnik",
    "Anlagenmechaniker/in",
    "Anlagenmechaniker/in – Rohrsystemtechnik",
    "Architektur",
    "Augenoptiker/in",
    "Automatenfachmann/-frau",
    "Automatisierungstechnik",
    "Automobilentwicklung",
    "Automobilkaufmann/-frau",
    "Automobilwirtschaft",
    "Automotive Engineering",
    "Autonome Systeme",
    "Aviation Management",
    "Bäcker/in",
    "Bankkaufmann/-frau",
    "Baugeräteführer/in",
    "Bauingenieurwesen",
    "Bauingenieurwesen - Hochbau und konstruktiver Ingenieurbau",
    "Bauingenieurwesen - Wasser- und Umwelttechnik",
    "Beton- und Stahlbetonbauer:in",
    "Bibliotheks- und Informationsmanagement",
    "Biochemie",
    "Bioinformatik",
    "Biologie",
    "Biologielaborant/in",
    "Biomedical Engineering",
    "Biomedizinische Technik",
    "Biomedizinische Wissenschaften",
    "Bionik",
    "Biotechnologie",
    "Bodenleger/in",
    "Buchhändler:in",
    "Chemie",
    "Chemielaborant/in",
    "Chemikant/in",
    "Computer Science (Informatik)",
    "Dachdecker/in",
    "Data Science",
    "Design (Produkt-/Industriedesign)",
    "Digital Humanities",
    "Drogist:in",
    "Elektroanlagenmonteur/in",
    "Elektroniker/in",
    "Elektroniker/in für Automatisierungstechnik",
    "Elektroniker/in für Betriebstechnik",
    "Elektroniker/in für Energie- und Gebäudetechnik",
    "Elektroniker/in für Informations- und Systemtechnik",
    "Elektrotechnik",
    "Energie- und Umweltmanagement",
    "Entrepreneurship und Innovationsmanagement",
    "Ernährungswissenschaften (Ökotrophologie)",
    "Erneuerbare Energien",
    "Erziehungswissenschaften (Pädagogik)",
    "Fachinformatiker/in",
    "Fachinformatiker/in für Anwendungsentwicklung",
    "Fachinformatiker/in für Systemintegration",
    "Fachkraft für Lagerlogistik",
    "Fachlagerist/in",
    "Fahrzeuglackierer/in",
    "Fahrzeugtechnik",
    "Feinwerkmechaniker/in",
    "Fertigungsmechaniker/in",
    "Fleischer:in",
    "Fliesenleger/in",
    "Florist/in",
    "Forstwissenschaften",
    "Fotograf/in",
    "Friseur / Friseurin",
    "Game Design",
    "Gärtner/in",
    "Gebäudereiniger/in",
    "Geographie",
    "Geowissenschaften",
    "Germanistik",
    "Gerüstbauer/in",
    "Geschichte",
    "Gesundheitsmanagement",
    "Gesundheitswissenschaften",
    "Glaser/in",
    "Goldschmied/in",
    "Grundschul-Lehramt",
    "Gymnasial-Lehramt",
    "Handelsfachwirt/in",
    "Hauswirtschafter/in",
    "Hebammenkunde",
    "Hörakustiker/in",
    "Hotelfachmann/-frau",
    "Industriekaufmann/-frau",
    "Industriemechaniker/in",
    "Informatik",
    "Ingenieurinformatik",
    "International Wirtschaft",
    "Internationale Beziehungen",
    "IT-Management",
    "IT-Sicherheit",
    "Journalismus",
    "Jura",
    "Kanalbauer/in",
    "Karosserie- und Fahrzeugbaumechaniker/in",
    "Kauffrau/-mann für Büromanagement",
    "Kauffrau/-mann für Marketingkommunikation",
    "Kauffrau/-mann für Spedition und Logistikdienstleistung",
    "Kaufmann/-frau im Einzelhandel",
    "Kfz-Mechatroniker/in",
    "Koch/Köchin",
    "Kognitive Neurowissenschaften",
    "Kommunikationsdesign",
    "Kommunikationsmanagement",
    "Kosmetiker/in",
    "Kulturwissenschaften",
    "Landwirt/in",
    "Linguistik (Sprachwissenschaft)",
    "Logistikmanagement",
    "Maler/in und Lackierer/in",
    "Maschinenbau",
    "Materialwissenschaften",
    "Mathematik",
    "Maurer/in",
    "Mechatronik",
    "Mechatroniker/in",
    "Medien- und Kommunikationswissenschaft",
    "Mediengestalter/in Digital und Print",
    "Medieninformatik",
    "Medienmanagement",
    "Medienpsychologie",
    "Medientechnik",
    "Medizin",
    "Medizinmanagement",
    "Medizintechnik",
    "Metallbauer/in",
    "Meteorologie",
    "Milchtechnologe/-technologin",
    "Musik",
    "Nachhaltige Energietechnologien",
    "Nachhaltigkeitsmanagement",
    "Nanotechnologie",
    "Notfallsanitäter/in",
    "Orthopädietechnik-Mechaniker/in",
    "Personaldienstleistungskaufmann/-frau",
    "Personalmanagement",
    "Pflegefachmann/-frau",
    "Pflegewissenschaften",
    "Pharmakant/in",
    "Pharmazie",
    "Philosophie",
    "Physik",
    "Politikwissenschaft",
    "Polizist:in (mittlerer Dienst)",
    "Psychologie",
    "Public Health",
    "Rechtsanwaltsfachangestellte/r",
    "Robotik",
    "Schornsteinfeger/in",
    "Software Engineering",
    "Soziale Arbeit",
    "Soziologie",
    "Sportmanagement",
    "Sportwissenschaft",
    "Steuerfachangestellte/r",
    "Straßenbauer/in",
    "Supply Chain Management",
    "Technische Physik",
    "Tischler/in",
    "Tourismus und Eventmanagement",
    "Tourismuskaufmann/-frau",
    "Umweltmanagement",
    "Umweltpsychologie",
    "Umwelttechnik",
    "Umweltwissenschaften",
    "Verfahrenstechnik",
    "Verkäufer/in",
    "Verkehrsplanung",
    "Verkehrswesen",
    "Versorgungs- und Entsorgungstechnik",
    "Volkswirtschaftslehre (VWL)",
    "Werkzeugmechaniker/in",
    "Wirtschaftsinformatik",
    "Wirtschaftsinformatik und Digitale Transformation",
    "Wirtschaftsingenieurwesen",
    "Wirtschaftsingenieurwesen - Elektrotechnik",
    "Wirtschaftsingenieurwesen - Maschinenbau",
    "Wirtschaftsingenieurwesen - Umwelttechnik",
    "Wirtschaftsmathematik",
    "Wirtschaftspsychologie",
    "Wirtschaftsrecht",
    "Zahnmedizin",
    "Zahnmedizinische/r Fachangestellte/r",
    "Zahntechniker/in",
    "Zimmerer/in",
  ];